import React from 'react';
import PropTypes from 'prop-types';
import {Link, graphql} from 'gatsby';
import StaticLayout from 'components/layouts/static-layout';
import Bio from 'components/bio';
import {Card, CardBody, Row} from 'components/wrappers';

const BlogPostTemplate = ({data, pageContext, location}) => {
  const {
    markdownRemark: {
      html: __html,
      excerpt,
      frontmatter: {title, description, date},
    },
    site: {
      siteMetadata: {title: mainTitle},
    },
  } = data;
  const {previous, next} = pageContext;
  return (
    <StaticLayout
      {...{
        contentPadding: true,
        container: true,
        location,
        seo: {title, description: description || excerpt},
      }}
    >
      <h1 className="sr-only">{`${mainTitle} Blog`}</h1>
      <article>
        <Row>
          <div className="col-lg-8">
            <Card>
              <CardBody>
                <h1>{title}</h1>
                <p>
                  <small>{date}</small>
                </p>
                <section dangerouslySetInnerHTML={{__html}} />
              </CardBody>
            </Card>
          </div>
          <div className="col-lg-4">
            <hr className="d-lg-none mt-0" />
            <Bio />
          </div>
        </Row>
        <Row>
          <div className="col-lg-8">
            <Card>
              <CardBody>
                <nav>
                  <ul
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'space-between',
                      listStyle: 'none',
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <li>
                      {previous && (
                        <Link to={previous.fields.slug} rel="prev">
                          ← {previous.frontmatter.title}
                        </Link>
                      )}
                    </li>
                    <li>
                      {next && (
                        <Link to={next.fields.slug} rel="next">
                          {next.frontmatter.title} →
                        </Link>
                      )}
                    </li>
                  </ul>
                </nav>
              </CardBody>
            </Card>
          </div>
        </Row>
      </article>
    </StaticLayout>
  );
};

BlogPostTemplate.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.object,
    markdownRemark: PropTypes.object,
    fields: PropTypes.object,
  }),
  pageContext: PropTypes.shape({
    next: PropTypes.object,
    previous: PropTypes.object,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default BlogPostTemplate;

export const GATSBY_QUERY = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: {slug: {eq: $slug}}) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`;
